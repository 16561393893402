import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 4×8\\@75% 1RM`}</p>
    <p>{`Ball Leg Curl 4×8`}</p>
    <p>{`then,`}</p>
    <p>{`1000M Row`}</p>
    <p>{`100 Wall Balls (20/14)`}</p>
    <p>{`50 T2B`}</p>
    <p>{`for time.`}</p>
    <p><em parentName="p">{`*`}{`compare to 12/28/18`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      